import {
  CarbonCaptureSource,
  CarbonCaptureSourceConfiguration,
  InputConfiguration,
  PowerCapacityConfiguration,
  PowerPlantConfiguration,
  PowerPlantType,
  PowerSupplyOption,
  StackReplacementType,
} from "../../types";

type InputMap = { [k: string]: number | string };

const dynamicDefaults = {
  hydrogen: {
    basic: {
      projectScale: 15,
      electrolyserPurchaseCost: 1500, // assuming this is electrolyser capital cost
      principalPPACost: 50,
      electrolyserNominalCapacity: 1,
      secAtNominalLoad: 39.38,
      electrolyserEfficiency: 70,
      waterRequirementOfElectrolyser: 15,
      electrolyserMaximumLoad: 100,
      electrolyserMinimumLoad: 10,
      maximumLoadWhenOverloading: 0,
      timeBetweenOverloading: 0,
      stackDegradation: 0.0,
      stackLifetime: 80_000,
      maximumDegradationBeforeReplacement: 10,
      electrolyserReferenceCapacity: 1000,
      electrolyserCostReductionWithScale: 5,
      electrolyserReferenceFoldIncrease: 10,
      electrolyserEpcCosts: 30,
      electrolyserLandProcurementCosts: 6,
      electrolyserOMCost: 3,
      electrolyserStackReplacement: 40,
      waterSupplyCost: 5,
      powerPlantOversizeRatio: 1.5,
      solarToWindPercentage: 50,
      solarDegradation: 0,
      windDegradation: 0,
      solarFarmBuildCost: 1200,
      solarReferenceCapacity: 1000,
      solarPVCostReductionWithScale: 5,
      solarReferenceFoldIncrease: 10,
      windFarmBuildCost: 2000,
      windReferenceCapacity: 1000,
      windCostReductionWithScale: 5,
      windReferenceFoldIncrease: 10,
      solarEpcCosts: 30,
      solarLandProcurementCosts: 6,
      windEpcCosts: 30,
      windLandProcurementCosts: 6,
      solarOpex: 17_000,
      windOpex: 25_000,
      gridConnectionCost: 1_000_000,
      additionalTransmissionCharges: 10,
      batteryRatedPower: 0,
      batteryStorageDuration: 0,
      batteryEfficiency: 0,
      batteryMinCharge: 0,
      batteryLifetime: 0,
      batteryCosts: 0,
      batteryEpcCosts: 0,
      batteryLandProcurementCosts: 0,
      batteryOMCost: 0,
      batteryReplacementCost: 0,
      additionalUpfrontCosts: 0,
      additionalAnnualCosts: 0,
      projectTimeline: 15,
      discountRate: 7,
      inflationRate: 2.5,
      stackReplacementType: "Cumulative Hours" as StackReplacementType,
      powerPlantConfiguration: "Standalone" as PowerPlantConfiguration,
      powerPlantType: "Hybrid" as PowerPlantType,
      powerSupplyOption: "Self Build" as PowerSupplyOption,
      powerCapacityConfiguration:
        "Oversize Ratio" as PowerCapacityConfiguration,
    },
    advanced: {
      electrolyserNominalCapacity: 100,
      secAtNominalLoad: 50,
      electrolyserEfficiency: 100,
      waterRequirementOfElectrolyser: 15,
      electrolyserMaximumLoad: 100,
      electrolyserMinimumLoad: 10,
      maximumLoadWhenOverloading: 100,
      timeBetweenOverloading: 0,
      stackDegradation: 0.0,
      stackLifetime: 80_000,
      maximumDegradationBeforeReplacement: 10,
      electrolyserReferenceCapacity: 1000,
      electrolyserPurchaseCost: 1500,
      electrolyserCostReductionWithScale: 5,
      electrolyserReferenceFoldIncrease: 10,
      electrolyserEpcCosts: 30,
      electrolyserLandProcurementCosts: 6,
      electrolyserOMCost: 3,
      electrolyserStackReplacement: 40,
      waterSupplyCost: 5,
      solarNominalCapacity: 150,
      windNominalCapacity: 150,
      powerPlantOversizeRatio: 1.5,
      solarToWindPercentage: 50,
      solarDegradation: 0,
      windDegradation: 0,
      solarFarmBuildCost: 1200,
      solarReferenceCapacity: 1000,
      solarPVCostReductionWithScale: 5,
      solarReferenceFoldIncrease: 10,
      windFarmBuildCost: 2000,
      windReferenceCapacity: 1000,
      windCostReductionWithScale: 5,
      windReferenceFoldIncrease: 10,
      solarEpcCosts: 30,
      solarLandProcurementCosts: 6,
      windEpcCosts: 30,
      windLandProcurementCosts: 6,
      solarOpex: 17_000,
      windOpex: 25_000,
      principalPPACost: 50,
      gridConnectionCost: 1_000_000,
      additionalTransmissionCharges: 10,
      batteryRatedPower: 0,
      batteryStorageDuration: 0,
      batteryEfficiency: 85,
      batteryMinCharge: 10,
      batteryLifetime: 10,
      batteryCosts: 380,
      batteryEpcCosts: 0,
      batteryLandProcurementCosts: 0,
      batteryOMCost: 10_000,
      batteryReplacementCost: 100,
      additionalUpfrontCosts: 0,
      additionalAnnualCosts: 0,
      projectTimeline: 15,
      discountRate: 7,
      inflationRate: 2.5,
      stackReplacementType: "Cumulative Hours" as StackReplacementType,
      powerPlantConfiguration: "Standalone" as PowerPlantConfiguration,
      powerPlantType: "Hybrid" as PowerPlantType,
      powerSupplyOption: "Self Build" as PowerSupplyOption,
      powerCapacityConfiguration:
        "Oversize Ratio" as PowerCapacityConfiguration,
    },
  },
  ammonia: {
    basic: {
      secAtNominalLoad: 39.4,
      waterRequirementOfElectrolyser: 15,
      electrolyserMaximumLoad: 100,
      electrolyserMinimumLoad: 10,
      stackDegradation: 0.0,
      stackLifetime: 80_000,
      maximumDegradationBeforeReplacement: 10,
      electrolyserReferenceCapacity: 1000,
      electrolyserPurchaseCost: 1500,
      electrolyserCostReductionWithScale: 10,
      electrolyserReferenceFoldIncrease: 10,
      electrolyserEpcCosts: 30,
      electrolyserLandProcurementCosts: 6,
      electrolyserOMCost: 2.5,
      electrolyserStackReplacement: 40,
      waterSupplyCost: 5,
      powerPlantOversizeRatio: 1.5,
      solarToWindPercentage: 50,
      solarDegradation: 0,
      windDegradation: 0,
      solarReferenceCapacity: 1000,
      solarPVCostReductionWithScale: 10,
      solarReferenceFoldIncrease: 10,
      windReferenceCapacity: 1000,
      windCostReductionWithScale: 10,
      windReferenceFoldIncrease: 10,
      solarEpcCosts: 30,
      solarLandProcurementCosts: 6,
      windEpcCosts: 30,
      windLandProcurementCosts: 6,
      solarOpex: 17_000,
      windOpex: 25_000,
      principalPPACost: 50,
      gridConnectionCost: 1_000_000,
      additionalTransmissionCharges: 10,
      batteryRatedPower: 0,
      batteryStorageDuration: 0,
      batteryEfficiency: 85,
      batteryMinCharge: 10,
      batteryLifetime: 10,
      batteryCosts: 380,
      batteryEpcCosts: 0,
      batteryLandProcurementCosts: 0,
      batteryOMCost: 10_000,
      batteryReplacementCost: 100,
      additionalUpfrontCosts: 0,
      additionalAnnualCosts: 0,
      projectTimeline: 25,
      discountRate: 7,
      inflationRate: 2.5,
      ammoniaPlantCapacity: 50,
      ammoniaPlantSec: 0.6,
      asuSec: 0.22,
      ammoniaPlantMinimumTurndown: 50,
      ammoniaStorageCapacity: 30,
      ammoniaSynthesisUnitCost: 400,
      ammoniaStorageCost: 100,
      airSeparationUnitCost: 200,
      ammoniaEpcCosts: 0,
      ammoniaLandProcurementCosts: 0,
      ammoniaPlantOMCost: 2,
      ammoniaStorageOMCost: 2,
      asuPlantOMCost: 2,
      hydrogenStoragePurchaseCost: 500,
      hydrogenStorageOMCost: 2,
      electrolyserEfficiency: 75,
      ammoniaPlantCapitalCost: 900,
      solarFarmBuildCost: 1200,
      windFarmBuildCost: 2000,
      electrolyserSystemOversizing: 45,
      hydrogenStorageCapacity: 50_000,
      minimumHydrogenStorage: 10,
      stackReplacementType: "Cumulative Hours" as StackReplacementType,
      powerPlantConfiguration: "Standalone" as PowerPlantConfiguration,
      powerPlantType: "Hybrid" as PowerPlantType,
      powerSupplyOption: "Self Build" as PowerSupplyOption,
      powerCapacityConfiguration:
        "Oversize Ratio" as PowerCapacityConfiguration,
    },
    advanced: {
      secAtNominalLoad: 50,
      electrolyserEfficiency: 100,
      waterRequirementOfElectrolyser: 15,
      electrolyserMaximumLoad: 100,
      electrolyserMinimumLoad: 10,
      stackDegradation: 0.0,
      stackLifetime: 80_000,
      maximumDegradationBeforeReplacement: 10,
      electrolyserReferenceCapacity: 1000,
      electrolyserPurchaseCost: 1500,
      electrolyserCostReductionWithScale: 10,
      electrolyserReferenceFoldIncrease: 10,
      electrolyserEpcCosts: 30,
      electrolyserLandProcurementCosts: 6,
      electrolyserOMCost: 2.5,
      electrolyserStackReplacement: 40,
      waterSupplyCost: 5,
      powerPlantOversizeRatio: 1.5,
      solarToWindPercentage: 50,
      solarDegradation: 0,
      windDegradation: 0,
      solarFarmBuildCost: 1200,
      solarReferenceCapacity: 1000,
      solarPVCostReductionWithScale: 10,
      solarReferenceFoldIncrease: 10,
      windFarmBuildCost: 2000,
      windReferenceCapacity: 1000,
      windCostReductionWithScale: 10,
      windReferenceFoldIncrease: 10,
      solarEpcCosts: 30,
      solarLandProcurementCosts: 6,
      windEpcCosts: 30,
      windLandProcurementCosts: 6,
      solarOpex: 17_000,
      windOpex: 25_000,
      principalPPACost: 50,
      gridConnectionCost: 1_000_000,
      additionalTransmissionCharges: 10,
      batteryRatedPower: 0,
      batteryStorageDuration: 0,
      batteryEfficiency: 85,
      batteryMinCharge: 10,
      batteryLifetime: 10,
      batteryCosts: 380,
      batteryEpcCosts: 0,
      batteryLandProcurementCosts: 0,
      batteryOMCost: 10_000,
      batteryReplacementCost: 100,
      additionalUpfrontCosts: 0,
      additionalAnnualCosts: 0,
      projectTimeline: 25,
      discountRate: 7,
      inflationRate: 2.5,
      ammoniaPlantCapacity: 50,
      ammoniaPlantSec: 0.4,
      asuSec: 0.2,
      ammoniaPlantMinimumTurndown: 33,
      ammoniaStorageCapacity: 30,
      ammoniaSynthesisUnitCost: 400,
      ammoniaStorageCost: 100,
      airSeparationUnitCost: 200,
      ammoniaEpcCosts: 0,
      ammoniaLandProcurementCosts: 0,
      ammoniaPlantOMCost: 2,
      ammoniaStorageOMCost: 2,
      asuPlantOMCost: 2,
      hydrogenStoragePurchaseCost: 500,
      hydrogenStorageOMCost: 2,
      electrolyserSystemOversizing: 45,
      hydrogenStorageCapacity: 50_000,
      minimumHydrogenStorage: 10,
      stackReplacementType: "Cumulative Hours" as StackReplacementType,
      powerPlantConfiguration: "Standalone" as PowerPlantConfiguration,
      powerPlantType: "Hybrid" as PowerPlantType,
      powerSupplyOption: "Self Build" as PowerSupplyOption,
      powerCapacityConfiguration:
        "Oversize Ratio" as PowerCapacityConfiguration,
    },
  },
  methanol: {
    basic: {
      secAtNominalLoad: 39.4,
      electrolyserEfficiency: 70,
      electrolyserMaximumLoad: 100,
      electrolyserMinimumLoad: 10,
      stackDegradation: 0.0,
      stackLifetime: 80_000,
      maximumDegradationBeforeReplacement: 10,
      electrolyserReferenceCapacity: 1000,
      electrolyserPurchaseCost: 1500,
      electrolyserReferenceFoldIncrease: 10,
      electrolyserEpcCosts: 30,
      electrolyserLandProcurementCosts: 6,
      electrolyserStackReplacement: 40,
      powerPlantOversizeRatio: 1.5,
      solarToWindPercentage: 50,
      solarDegradation: 0,
      windDegradation: 0,
      solarReferenceCapacity: 1000,
      solarReferenceFoldIncrease: 10,
      windReferenceCapacity: 1000,
      windReferenceFoldIncrease: 10,
      solarEpcCosts: 30,
      solarLandProcurementCosts: 6,
      windEpcCosts: 30,
      windLandProcurementCosts: 6,
      solarOpex: 17_000,
      windOpex: 25_000,
      gridConnectionCost: 1_000_000,
      additionalTransmissionCharges: 10,
      batteryStorageDuration: 8,
      batteryEfficiency: 85,
      batteryMinCharge: 0,
      batteryLifetime: 10,
      batteryCosts: 380,
      batteryEpcCosts: 0,
      batteryLandProcurementCosts: 0,
      batteryOMCost: 10_000,
      batteryReplacementCost: 100,
      additionalUpfrontCosts: 0,
      additionalAnnualCosts: 0,
      inflationRate: 2.5,
      hydrogenStoragePurchaseCost: 500,
      hydrogenStorageOMCost: 1,
      electrolyserSystemOversizing: 45,
      minimumHydrogenStorage: 10,
      methanolPlantCapacity: 400,
      methanolPlantSec: 0.36,
      methanolPlantMinimumTurndown: 100,
      methanolStorageCapacity: 30,
      methanolPlantUnitCost: 300,
      methanolStorageCost: 227,
      methanolPlantOMCost: 5,
      methanolStorageOMCost: 0,
      ccSec: 0.86,
      ccPlantCost: 1610,
      ccPlantOMCost: 5,
      ccEpcCosts: 0,
      ccLandProcurementCosts: 0,
      methanolEpcCosts: 10,
      methanolLandProcurementCosts: 0,
      waterRequirementOfElectrolyser: 25,
      electrolyserCostReductionWithScale: 5,
      electrolyserOMCost: 2.5,
      waterSupplyCost: 2.5,
      solarFarmBuildCost: 1200,
      solarPVCostReductionWithScale: 5,
      windFarmBuildCost: 2000,
      windCostReductionWithScale: 5,
      principalPPACost: 65,
      hydrogenStorageCapacity: 500_000,
      discountRate: 7,
      projectTimeline: 25,
      carbonCaptureSource: "Cement Plant" as CarbonCaptureSource,
      ccSourceConfiguration:
        "Preset Source" as CarbonCaptureSourceConfiguration,
      stackReplacementType: "Cumulative Hours" as StackReplacementType,
      powerPlantConfiguration: "Standalone" as PowerPlantConfiguration,
      powerPlantType: "Hybrid" as PowerPlantType,
      powerSupplyOption: "Self Build" as PowerSupplyOption,
      powerCapacityConfiguration:
        "Oversize Ratio" as PowerCapacityConfiguration,
    },
    advanced: {
      secAtNominalLoad: 50,
      electrolyserEfficiency: 100,
      electrolyserMaximumLoad: 100,
      electrolyserMinimumLoad: 10,
      stackDegradation: 0.0,
      stackLifetime: 80_000,
      maximumDegradationBeforeReplacement: 10,
      electrolyserReferenceCapacity: 1000,
      electrolyserPurchaseCost: 1500,
      electrolyserReferenceFoldIncrease: 10,
      electrolyserEpcCosts: 30,
      electrolyserLandProcurementCosts: 6,
      electrolyserStackReplacement: 40,
      powerPlantOversizeRatio: 1.5,
      solarToWindPercentage: 50,
      solarDegradation: 0,
      windDegradation: 0,
      solarReferenceCapacity: 1000,
      solarReferenceFoldIncrease: 10,
      windReferenceCapacity: 1000,
      windReferenceFoldIncrease: 10,
      solarEpcCosts: 30,
      solarLandProcurementCosts: 6,
      windEpcCosts: 30,
      windLandProcurementCosts: 6,
      solarOpex: 17_000,
      windOpex: 25_000,
      gridConnectionCost: 1_000_000,
      additionalTransmissionCharges: 10,
      batteryStorageDuration: 8,
      batteryEfficiency: 85,
      batteryMinCharge: 0,
      batteryLifetime: 10,
      batteryCosts: 380,
      batteryEpcCosts: 0,
      batteryLandProcurementCosts: 0,
      batteryOMCost: 10_000,
      batteryReplacementCost: 100,
      additionalUpfrontCosts: 0,
      additionalAnnualCosts: 0,
      projectTimeline: 25,
      discountRate: 7,
      inflationRate: 2.5,
      hydrogenStoragePurchaseCost: 500,
      hydrogenStorageOMCost: 1,
      electrolyserSystemOversizing: 45,
      hydrogenStorageCapacity: 50_000,
      minimumHydrogenStorage: 10,
      methanolPlantCapacity: 400,
      methanolPlantSec: 0.36,
      methanolPlantMinimumTurndown: 100,
      methanolStorageCapacity: 30,
      methanolPlantUnitCost: 300,
      methanolStorageCost: 227,
      methanolPlantOMCost: 5,
      methanolStorageOMCost: 0,
      ccSec: 0.86,
      ccPlantCost: 1610,
      ccPlantOMCost: 5,
      ccEpcCosts: 0,
      ccLandProcurementCosts: 0,
      methanolEpcCosts: 10,
      methanolLandProcurementCosts: 0,
      waterRequirementOfElectrolyser: 25,
      electrolyserCostReductionWithScale: 5,
      electrolyserOMCost: 2.5,
      waterSupplyCost: 2.5,
      solarFarmBuildCost: 1200,
      solarPVCostReductionWithScale: 5,
      windFarmBuildCost: 2000,
      windCostReductionWithScale: 5,
      principalPPACost: 65,
      carbonCaptureSource: "Cement Plant" as CarbonCaptureSource,
      ccSourceConfiguration:
        "Preset Source" as CarbonCaptureSourceConfiguration,
      stackReplacementType: "Cumulative Hours" as StackReplacementType,
      powerPlantConfiguration: "Standalone" as PowerPlantConfiguration,
      powerPlantType: "Hybrid" as PowerPlantType,
      powerSupplyOption: "Self Build" as PowerSupplyOption,
      powerCapacityConfiguration:
        "Oversize Ratio" as PowerCapacityConfiguration,
    },
  },
  methane: {
    basic: {
      secAtNominalLoad: 39.4,
      electrolyserEfficiency: 70,
      electrolyserMaximumLoad: 100,
      electrolyserMinimumLoad: 10,
      stackDegradation: 0.0,
      stackLifetime: 80_000,
      maximumDegradationBeforeReplacement: 10,
      electrolyserReferenceCapacity: 1000,
      electrolyserPurchaseCost: 1500,
      electrolyserReferenceFoldIncrease: 10,
      electrolyserEpcCosts: 30,
      electrolyserLandProcurementCosts: 6,
      electrolyserStackReplacement: 40,
      solarNominalCapacity: 150,
      windNominalCapacity: 150,
      powerPlantOversizeRatio: 1.5,
      solarToWindPercentage: 50,
      solarDegradation: 0,
      windDegradation: 0,
      solarReferenceCapacity: 1000,
      solarReferenceFoldIncrease: 10,
      windReferenceCapacity: 1000,
      windReferenceFoldIncrease: 10,
      solarEpcCosts: 30,
      solarLandProcurementCosts: 6,
      windEpcCosts: 30,
      windLandProcurementCosts: 6,
      solarOpex: 17_000,
      windOpex: 25_000,
      gridConnectionCost: 1_000_000,
      additionalTransmissionCharges: 10,
      batteryStorageDuration: 8,
      batteryEfficiency: 85,
      batteryMinCharge: 0,
      batteryLifetime: 10,
      batteryCosts: 380,
      batteryEpcCosts: 0,
      batteryLandProcurementCosts: 0,
      batteryOMCost: 10_000,
      batteryReplacementCost: 100,
      additionalUpfrontCosts: 0,
      additionalAnnualCosts: 0,
      projectTimeline: 25,
      discountRate: 7,
      inflationRate: 2.5,
      hydrogenStoragePurchaseCost: 500,
      hydrogenStorageOMCost: 1,
      electrolyserSystemOversizing: 45,
      hydrogenStorageCapacity: 50_000,
      minimumHydrogenStorage: 10,
      ccSec: 0.86,
      ccPlantCost: 1610,
      ccPlantOMCost: 5,
      ccEpcCosts: 0,
      ccLandProcurementCosts: 0,
      waterRequirementOfElectrolyser: 25,
      electrolyserCostReductionWithScale: 5,
      electrolyserOMCost: 2.5,
      waterSupplyCost: 2.5,
      solarFarmBuildCost: 1200,
      solarPVCostReductionWithScale: 5,
      windFarmBuildCost: 2000,
      windCostReductionWithScale: 5,
      principalPPACost: 65,
      methanePlantCapacity: 400,
      methanePlantSec: 0.51,
      methanePlantMinimumTurndown: 100,
      methaneStorageCapacity: 30,
      methanePlantUnitCost: 400,
      methaneEpcCosts: 0,
      methaneLandProcurementCosts: 0,
      methanePlantOMCost: 5,
      methaneStorageCost: 227,
      methaneStorageOMCost: 5,
      carbonCaptureSource: "Cement Plant" as CarbonCaptureSource,
      ccSourceConfiguration:
        "Preset Source" as CarbonCaptureSourceConfiguration,
      stackReplacementType: "Cumulative Hours" as StackReplacementType,
      powerPlantConfiguration: "Standalone" as PowerPlantConfiguration,
      powerPlantType: "Hybrid" as PowerPlantType,
      powerSupplyOption: "Self Build" as PowerSupplyOption,
      powerCapacityConfiguration:
        "Oversize Ratio" as PowerCapacityConfiguration,
    },
    advanced: {
      secAtNominalLoad: 50,
      electrolyserEfficiency: 100,
      electrolyserMaximumLoad: 100,
      electrolyserMinimumLoad: 10,
      stackDegradation: 0.0,
      stackLifetime: 80_000,
      maximumDegradationBeforeReplacement: 10,
      electrolyserReferenceCapacity: 1000,
      electrolyserPurchaseCost: 1500,
      electrolyserReferenceFoldIncrease: 10,
      electrolyserEpcCosts: 30,
      electrolyserLandProcurementCosts: 6,
      electrolyserStackReplacement: 40,
      solarNominalCapacity: 150,
      windNominalCapacity: 150,
      powerPlantOversizeRatio: 1.5,
      solarToWindPercentage: 50,
      solarDegradation: 0,
      windDegradation: 0,
      solarReferenceCapacity: 1000,
      solarReferenceFoldIncrease: 10,
      windReferenceCapacity: 1000,
      windReferenceFoldIncrease: 10,
      solarEpcCosts: 30,
      solarLandProcurementCosts: 6,
      windEpcCosts: 30,
      windLandProcurementCosts: 6,
      solarOpex: 17_000,
      windOpex: 25_000,
      gridConnectionCost: 1_000_000,
      additionalTransmissionCharges: 10,
      batteryStorageDuration: 8,
      batteryEfficiency: 85,
      batteryMinCharge: 0,
      batteryLifetime: 10,
      batteryCosts: 380,
      batteryEpcCosts: 0,
      batteryLandProcurementCosts: 0,
      batteryOMCost: 10_000,
      batteryReplacementCost: 100,
      additionalUpfrontCosts: 0,
      additionalAnnualCosts: 0,
      projectTimeline: 25,
      discountRate: 7,
      inflationRate: 2.5,
      hydrogenStoragePurchaseCost: 500,
      hydrogenStorageOMCost: 1,
      electrolyserSystemOversizing: 45,
      hydrogenStorageCapacity: 50_000,
      minimumHydrogenStorage: 10,
      ccSec: 0.86,
      ccPlantCost: 1610,
      ccPlantOMCost: 5,
      ccEpcCosts: 0,
      ccLandProcurementCosts: 0,
      methanolEpcCosts: 10,
      methanolLandProcurementCosts: 0,
      waterRequirementOfElectrolyser: 25,
      electrolyserCostReductionWithScale: 5,
      electrolyserOMCost: 2.5,
      waterSupplyCost: 2.5,
      solarFarmBuildCost: 1200,
      solarPVCostReductionWithScale: 5,
      windFarmBuildCost: 2000,
      windCostReductionWithScale: 5,
      principalPPACost: 65,
      methanePlantCapacity: 400,
      methanePlantSec: 0.51,
      methanePlantMinimumTurndown: 100,
      methaneStorageCapacity: 30,
      methanePlantUnitCost: 400,
      methaneEpcCosts: 0,
      methaneLandProcurementCosts: 0,
      methanePlantOMCost: 5,
      methaneStorageCost: 227,
      methaneStorageOMCost: 5,
      carbonCaptureSource: "Cement Plant" as CarbonCaptureSource,
      ccSourceConfiguration:
        "Preset Source" as CarbonCaptureSourceConfiguration,
      stackReplacementType: "Cumulative Hours" as StackReplacementType,
      powerPlantConfiguration: "Standalone" as PowerPlantConfiguration,
      powerPlantType: "Hybrid" as PowerPlantType,
      powerSupplyOption: "Self Build" as PowerSupplyOption,
      powerCapacityConfiguration:
        "Oversize Ratio" as PowerCapacityConfiguration,
    },
  },
};

export function getDefaultInputs(
  powerfuel: string,
  inputConfiguration: InputConfiguration,
  offshore: boolean
): InputMap {
  // fetch saved data or {}
  // if inputConfiguration === 'Basic' and savedData.inputConfiguration === 'Advanced', drop saved state as we don't want advanced state corruping basic case
  // given powerfuel, get from saved data or fall back to map indexed by powerfuel

  const sessionStorageData: InputMap = JSON.parse(
    sessionStorage.getItem(`${powerfuel}${inputConfiguration}`) || "{}"
  );

  const savedData: InputMap =
    sessionStorageData["inputConfiguration"] === "Advanced" &&
    inputConfiguration === "Basic"
      ? {}
      : sessionStorageData;

  type ObjectKey = keyof typeof dynamicDefaults;
  const typedPowerfuel = powerfuel as ObjectKey;
  const powerfuelDefaults: InputMap =
    inputConfiguration === "Basic"
      ? dynamicDefaults[typedPowerfuel]["basic"]
      : dynamicDefaults[typedPowerfuel]["advanced"];

  const defaults: InputMap = {};

  Object.keys(powerfuelDefaults).forEach((key: string | number) => {
    defaults[key] =
      savedData[key] === undefined ? powerfuelDefaults[key] : savedData[key];
  });

  if (offshore) {
    defaults["powerPlantType"] = "Wind";
    defaults["solarToWindPercentage"] = 0;
  }

  return defaults;
}
